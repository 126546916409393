(function($) {

    $(document).ready(function() {

        var headHeight = $('.js-site-head > .contented').outerHeight();

        /*
         * Alt Header on scroll
         */
        stickHeader();
        window.addEventListener('scroll', function(e) {
            stickHeader();
        });

        function stickHeader() {
            var distanceY = window.pageYOffset || document.documentElement.scrollTop,
                shrinkOn = headHeight,
                header = $('.js-site-head');
            if (distanceY > shrinkOn) {
                header.addClass('isSticky');

            } else {
                if (header.hasClass('isSticky')) {
                    header.removeClass('isSticky');
                }
            }
        }




        function scrollPageTop() {
            var scrollTop = $('.js-site-head > .contented').outerHeight();
            $("html, body").animate({ scrollTop: scrollTop+'px' }, 0);
        }

        /**
         * Menu Toggle
         */
        var menuOpen = false;
        $('.js-menu-toggle').on('click', function(evt) {
            evt.preventDefault();
            closeSearch();

            if(!menuOpen) {
                scrollPageTop();
            }

            $(this).toggleClass('isOpen');
            $('.js-nav-primary').toggleClass('isOpen');
            $('.js-site-head').toggleClass('isOpen');

            !menuOpen ? menuOpen = true : menuOpen = false;

            // min height
            $('.js-nav-primary').css('min-height', $('body').outerHeight());
        });


        function closeMenu() {
            $('.js-menu-toggle').removeClass('isOpen');
            $('.js-nav-primary').removeClass('isOpen');
            $('.js-site-head').removeClass('isOpen');
        }

        $(window).resize(function(){
            //closeMenu();
        });

        $('.do-form-toggle').on('click', function(evt) {
            $(this).toggleClass('isOpen');
            $(this).parent().find('li.fancy-checkbox').toggleClass('isOpen');
        });


        /**
         * Search Toggle
         */
        var searchOpen = false;
        $('.js-search-toggle').on('click', function(evt) {
            evt.preventDefault();
            closeMenu();

            if(!searchOpen) {
                scrollPageTop();
            }

            $('.js-search-toggle').toggleClass('isOpen');
            $('.js-search-primary').toggleClass('isOpen');
            $('.js-site-head').toggleClass('isOpen');

            !searchOpen ? searchOpen = true : searchOpen = false;

            //min height
            $('.js-search-primary').css('min-height', $('body').outerHeight());
        });

        function closeSearch() {
            $('.js-search-toggle').removeClass('isOpen');
            $('.js-search-primary').removeClass('isOpen');
            $('.js-site-head').removeClass('isOpen');
        }

        $(window).resize(function(){
            //closeSearch();
        });


        /**
         *
         * Location Gallery
         *
         */
        $("#locationGallery").lightGallery({
            selector: '.js-gallery-item',
            download: false,
            counter: false,
            thumbnail: true
        });



        /**
         * Tabs
         */
        $('.js-accordion-control').on('click', function(evt) {
            evt.preventDefault();

            var $tab = $(this).closest('.js-accordion-item');
            var $this = $(this);

            $tab.find('.accordion-body').toggleClass('isActive');
            $this.toggleClass('isActive');
        });




        /**
         * Simple Toggle
         * Show and hide item
         */
        $('.js-toggle').on('click', function(evt) {
            evt.preventDefault();

            var toggle = $(this).data('toggle');

            $('#'+toggle).toggleClass('isHidden');
        });




        /**
         * Slick JS
         * Config in data attributes
         */
        //$('.js-slick').slick();



        /**
         * Tabs
         */
        $('.js-tabs-control').on('click', function(evt) {
            evt.preventDefault();

            var $tab = $(this).data('tab');
            var $this = $(this);

            $('.js-tabs-panel').addClass('hidden');
            $('.js-tabs-control').removeClass('active');

            setTimeout(function(){
                $($tab).removeClass('hidden');
                $this.addClass('active');
            }, 100);
        });

        /**
         *
         * smooth scroll
         *
         */
        $('a[href*="#"]:not([href="#"])').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
              if (target.length) {
                $('html, body').animate({
                  scrollTop: target.offset().top
                }, 1000);
                return false;
              }
            }
          });



        /**
         *
         * AOS int
         *
         */
        // AOS.init({
        //     offset: 250,
        //     duration: 400,
        //     easing: 'ease-in'
        // });
    });

})(jQuery); // Fully reference jQuery after this point.
